import api from '@/config/axios'
import apiMonolith from '@/config/axiosMonolith'
import { db } from '@/config/firebase'
import { getAuth } from 'firebase/auth'
import {
  collection,
  collectionGroup,
  type CollectionReference,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  query,
  Query,
  startAfter
} from 'firebase/firestore'

export default class BaseService {
  public organizationId = localStorage.getItem('activeOrganization')

  protected async call<T>(endpoint: string, data?: T, method = 'POST', isSetOrganization = true) {
    const token = await this.idToken()
    const headers = {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }

    if (method === 'GET') {
      return await api.get(endpoint, {
        params: {
          ...data,
          organization_id: isSetOrganization ? this.organizationId : undefined
        },
        headers
      })
    }

    return await api.post(
      endpoint,
      {
        ...data,
        organization_id: isSetOrganization ? this.organizationId : undefined
      },
      {
        headers
      }
    )
  }

  protected async callMonolith<T>(endpoint: string, data?: T, method = 'POST', isSetOrganization = true) {
    const token = await this.idToken()
    const headers = {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }

    if (method === 'GET') {
      return await apiMonolith.get(endpoint, {
        params: {
          ...data,
          organization_id: isSetOrganization ? this.organizationId : undefined
        },
        headers
      })
    }

    return await apiMonolith.post(
      endpoint,
      {
        ...data,
        organization_id: isSetOrganization ? this.organizationId : undefined
      },
      {
        headers
      }
    )
  }

  protected async paginateFirestore(
    ref: CollectionReference | Query,
    conds: any[],
    lastDoc?: any,
    isLimit = true
  ) {
    const q = query(
      ref,
      ...conds,
      ...(isLimit ? [limit(10)] : []),
      ...(lastDoc ? [startAfter(lastDoc)] : [])
    )
    const qCounter = query(ref, ...conds)
    const [snapshot, totalItems] = await Promise.all([getDocs(q), getCountFromServer(qCounter)])
    return {
      docs: snapshot?.docs,
      totalItems: totalItems?.data?.()?.count
    }
  }

  protected async getActiveOrganizationData() {
    return (await getDoc(doc(db, 'organizations', this.organizationId as string)))?.data()
  }

  protected getOrganizationRef() {
    return doc(db, 'organizations', this.organizationId as string)
  }

  protected getCollectionPath(collectionName: string, isCollectionGroup: boolean) {
    if (!isCollectionGroup) return collection(db, collectionName)
    return collectionGroup(db, collectionName)
  }

  protected async getProviderData() {
    const orgData = await this.getActiveOrganizationData()
    const providerId = orgData?.provider_id
    const provider = await getDoc(doc(db, 'providers', providerId))
    return provider.data()
  }

  private async idToken() {
    return await getAuth().currentUser?.getIdToken(true)
  }
}
