import axios from 'axios'
import Environment from '@/config/environment'

const apiMonolith = axios.create({
  baseURL: Environment.BASE_API_MONOLITH,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

export default apiMonolith